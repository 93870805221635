body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  padding-bottom: 30px;
  margin-top: 20px !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  padding: 0.4rem 0;
  text-align: center;
  margin: auto;
  color: #00d0c3;
  border: 3px dotted #00d0c3;
  width: 30%;
}

.topSpace{
  margin-top: 100px;
}

.padingSpace{
  padding-left: 50px;
  padding-right: 50px;
}

.error_info{
  text-align: center;
  margin-top: 200px;
}

.filterSelect{
  color: black!important;
}

.data-table-extensions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.multiselectInput{
  color: black!important;
  width: 100%;
}